
import {
  ComponentPublicInstance,
  computed,
  defineComponent,
  nextTick,
  onBeforeUnmount,
  onMounted,
  reactive,
  ref, watch
} from 'vue';
import UITable from '@/components/UI/UITable.vue';
import UITableNav from '@/components/UI/UITableNav.vue';
import UISimpleInput from '@/components/UI/UISimpleInput.vue';
import ExcelReq from '@/components/common/ExcelRequest.vue';
import RumaFilter from '@/components/common/RumaFilter.vue';
import { useRoute } from 'vue-router';
import UIModal from '@/components/UI/UIModal.vue';
import UIButton from '@/components/UI/UIButton.vue';
import UITextInput from '@/components/UI/UITextInput.vue';
import UITextDropdown from '@/components/UI/UITextSelect.vue';
import {
  especiesState,
  estadoMaderasState,
  estadoRumasState, getCanchaById,
  getProcedenciaNyId, getProductoById,
  zonasState
} from '@/store/mantenedores.store';
import {makeRequest, METHODS, rumaImageUrl} from '@/utils/apiUtils';
import { IRumaApi } from '@/models/Mantenedores';
import { cleanSource } from '@/utils';
import { showToast } from '@/hooks/useToast';
import { userState } from '@/store/user.store';
import { SocketIO } from '@/utils/socketUtils';
import {useRumaImage} from "@/composables/useRumaImage";

export default defineComponent({
  name: 'RumaList',
  methods: {rumaImageUrl},
  components: {
    'ui-table': UITable,
    'ui-table-nav': UITableNav,
    'excel-req': ExcelReq,
    'ui-simple-input': UISimpleInput,
    'ruma-filter': RumaFilter,
    'ui-modal': UIModal,
    'ui-button': UIButton,
    'ui-text-input': UITextInput,
    'ui-text-select': UITextDropdown
  },
  setup() {
    const mainTable = ref<any>(null);
    const route = useRoute();
    const routeName = computed(() => (route.meta?.routeName ? route.meta?.routeName : 'no-name'));
    const singleRumaSearchRef = ref<ComponentPublicInstance<typeof UISimpleInput> | null>(null);
    const isLoading = ref(false);
    const getZonaById = (id: string) => {
      return zonasState.value.find(el => el._id === id)?.descripcion || '';
    };

    const modalState = ref(false);

    const tableKey = ref(0);

    const headers = ref([
      { label: 'Ruma', key: 'ruma', format: '' },
      { label: 'Estado Ruma', key: 'estadoRuma', format: '' },
      { label: 'Condición Ruma', key: 'condicionRuma', format: '' },
      { label: 'Mes Corta', key: 'mesCorta', format: '' },
      { label: 'A.Plant', key: 'anioPlantacion', format: '' },
      { label: 'Especie', key: 'especie', format: '' },
      { label: 'Producto', key: 'producto', format: '' },
      { label: 'Largo', key: 'largo', format: '' },
      { label: 'Cod. Cancha', key: 'codCancha', format: '' },
      { label: 'Cancha', key: 'cancha', format: '' },
      { label: 'Vol.MR Original', key: 'volCalculado', format: '', hidden: false },
      { label: 'Vol.MR', key: 'volMR', format: '' },
      { label: 'Vol. M3Ssc', key: 'volM3', format: '' }
    ]);
    const actions = [
      {
        label: 'ESTADO',
        class: 'bg-yellow-600 text-white',
        cb: (i: number) => rumaStatusUpdate(i)
      },
      {
        label: 'DETALLE',
        class: 'bg-yellow-900 text-white',
        cb: (i: number) => requestRumaGuidesDetails(i)
      },
      {
        label: 'EDITAR',
        class: 'bg-green-400 text-white',
        cb: (i: number) => rumaEditModal(i)
      }
    ];

    const items = ref<{ uid: string; data: any }[]>([]);
    const itemsCount = reactive<{
      total: number;
      actual: number;
      initial: number;
    }>({ total: 0, actual: 0, initial: 0 });

    const filter = reactive<{ [k: string]: any }>({
      estado: null,
      qty: 10,
      page: 1,
      ruma: '',
      cancha: null,
      especie: null
    });
    const rumaGuidesHeaders = ref([
      { label: 'Fecha', key: 'fechaGuia', format: '' },
      { label: 'Movimiento', key: 'movimiento', format: 'movimiento' },
      { label: 'Guía', key: 'guiaArauco', format: '' },
      { label: 'Origen', key: 'origen', format: '' },
      { label: 'Destino', key: 'destino', format: '' },
      { label: 'Producto', key: 'producto', format: '' },
      { label: 'Volumen MR', key: 'volumenMR', format: '' },
      { label: 'Volumen MSSC', key: 'volumenMSSC', format: '' },
      { label: 'MR Original', key: 'originalMR', format: '', hidden: false },
    ]);
    const ajustesHeaders = ref([
      { label: 'Zona', key: 'zona', format: '' },
      { label: 'Procedencia', key: 'procedencia', format: '' },
      { label: 'Producto', key: 'producto', format: '' },
      { label: 'Origen', key: 'origen', format: '' },
      { label: 'Destino', key: 'destino', format: '' },
      { label: 'Valor Ajuste', key: 'valorAjuste', format: '' },
      { label: 'Valor Ajuste M3', key: 'valorAjusteM3', format: '' },
      { label: 'Valor Ajuste MR', key: 'valorAjusteMR', format: '' },
    ]);
    const rumaGuideAjustes = ref({ valorAjuste: 0, valorAjusteM3: 0 });
    const rumaAjustesList = ref<any>([]);
    const rumaAjustedFilteres = ref<any>([]);
    const modalStateGuides = ref(false);
    const isLoadingRumaGuides = ref(false);
    const rumaGuideSelected = ref<any>(null);
    const rumaGuidesDetails = ref<any[]>([]);
    const rumaGroups = ref<any[]>([]);
    const moveTranslator = (move: number) => {
      switch(move) {
        case 1:
          return 'COMPRA';
        case 2:
          return 'VENTA';
        case 3:
          return 'DESPACHO';
        case 4:
          return 'RECEPCIÓN';
        case 5:
          return 'AJUSTE';
        default:
          return '---';
      }
    };
    const formatRumaGuide = (guide: any) => {
      return {
        id: guide.id,
        fechaGuia: guide.fechaGuia,
        movimiento: moveTranslator(guide.movimiento),
        guiaArauco: guide.guiaArauco,
        origen: guide.origen,
        destino: guide.destino,
        producto: guide.producto,
        volumenMR: guide.volumenMR || 0,
        volumenMSSC: guide.volumenMSSC || 0,
        originalMR: guide.originalMR || 0,
        zona: guide.zona._id,
        zonaName: guide.zona.descripcion,
        procedencia: guide.procedencia
      };
    };
    const formatAjusteToList = (ajuste: any) => {
      return {
        id: ajuste._id,
        zona: getZonaById(ajuste.zona),
        zonaId: ajuste.zona,
        procedencia: getProcedenciaNyId(ajuste.procedencia)?.descripcion  || '---',
        procedenciaId: ajuste.procedencia,
        producto: getProductoById(ajuste.producto)?.codigo  || '---',
        productoId: ajuste.producto,
        origen: getCanchaById(ajuste.origen)?.descripcion || '---',
        destino: getCanchaById(ajuste.destino)?.descripcion  || '---',
        valorAjuste: ajuste.valorAjuste,
        valorAjusteM3: ajuste.valorAjusteM3,
        valorAjusteMR: ajuste.valorAjusteMR,
        tipoAjuste: ajuste.tipoAjuste,
        tipoAjusteText: ajuste.tipoAjuste === 'ADD' ? 'AÑADIR' : 'REDUCIR'
      };
    };
    function removeAccents(str: string) {
      return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    }
    const requestRumaGuidesDetails = async (i: number) => {
      if (isLoadingRumaGuides.value) return;
      try {
        isShowingDetails.value = false;
        isLoadingRumaGuides.value = true;
        rumaGuidesDetails.value = [];
        rumaAjustesList.value = [];
        rumaAjustedFilteres.value = [];
        const { _id } = items.value[i].data;
        const request = await makeRequest<any>(METHODS.GET, `ruma-guias/${_id}`, true);
        if (request.ok) {
          rumaGuideSelected.value = items.value[i].data;
          // const rumaGuidesDetails = request.payload.items.guides.map((el:any) => ({
          //   id: el.id,
          //   fechaGuia: el.fechaGuia,
          //   movimiento: moveTranslator(el.movimiento),
          //   guiaArauco: el.guiaArauco,
          //   origen: el.origen,
          //   destino: el.destino,
          //   producto: el.producto,
          //   volumenMR: el.volumenMR || 0,
          //   volumenMSSC: el.volumenMSSC || 0,
          //   originalMR: el.originalMR || 0,
          //   zona: el.zona,
          //   procedencia: el.procedencia
          // }));
          rumaGroups.value = request.payload.items.guides.reduce((acc: any, el: any) => {
            const guide = formatRumaGuide(el);
            const cont = acc.find((g: any) => g.zona === guide.zona && g.procedencia === guide.procedencia);
            if (cont) {
              cont.guides.push(guide);
            } else {
              acc.push({
                zona: guide.zona,
                zonaName: guide.zonaName,
                procedencia: guide.procedencia,
                guides: [guide]
              });
            }
            return acc;
          }, []);

          rumaAjustesList.value = request.payload.items.ajustesRaw.map((el: any) => formatAjusteToList(el));

          // rumaGuidesDetails.value = rumaReduced;
          //
          rumaGuideAjustes.value.valorAjuste = request.payload.items.ajustes.valorAjuste;
          rumaGuideAjustes.value.valorAjusteM3 = request.payload.items.ajustes.valorAjusteM3;
          modalStateGuides.value = true;
        } else {
          rumaGuidesDetails.value = [];
          rumaGuideSelected.value = null;
          rumaGuideAjustes.value.valorAjuste = 0;
          rumaGuideAjustes.value.valorAjusteM3 = 0;
        }
      } catch (error) {
        console.log(error);
        rumaGuidesDetails.value = [];
        rumaGuideSelected.value = null;
        rumaGuideAjustes.value.valorAjuste = 0;
        rumaGuideAjustes.value.valorAjusteM3 = 0;
      } finally {
        isLoadingRumaGuides.value = false;
      }
    };

    const rumaGroupTotals = ref({
      volMR: 0,
      volM3: 0,
      volMROriginal: 0
    });
    const ajusteGroupTotals = ref({
      volMR: 0,
      volM3: 0,
      volMROriginal: 0
    });

    const isShowingDetails = ref(false);
    const setRumaGroupsGuides = (guides: any[], isDetails: boolean, opt?: { zona: string, procedencia: string }) => {
      rumaGuidesDetails.value = guides;
      rumaGroupTotals.value = guides.reduce((acc: any, el: any) => {
        // acc.volMR += el.volumenMR;
        // acc.volM3 += el.volumenMSSC;
        // acc.volMROriginal += el.originalMR;

        if (['COMPRA', 'RECEPCIÓN'].includes(el.movimiento)) {
          acc.volMR += el.volumenMR;
          acc.volM3 += el.volumenMSSC;
          acc.volMROriginal += el.originalMR;
        }

        if (['VENTA', 'DESPACHO'].includes(el.movimiento)) {
          acc.volMR -= el.volumenMR;
          acc.volM3 -= el.volumenMSSC;
          acc.volMROriginal -= el.originalMR;
        }

        acc.volMR = +(acc.volMR).toFixed(2);
        acc.volM3 = +(acc.volM3).toFixed(2);
        acc.volMROriginal = +(acc.volMROriginal).toFixed(2);
        return acc;
      }, { volMR: 0, volM3: 0, volMROriginal: 0 });
      rumaAjustedFilteres.value = [];
      if (isDetails && opt) {
        rumaAjustedFilteres.value = rumaAjustesList.value.filter((el: any) => el.zonaId === opt.zona && removeAccents(el.procedencia) === removeAccents(opt.procedencia));
        ajusteGroupTotals.value = rumaAjustedFilteres.value.reduce((acc: any, el: any) => {
          if (el.tipoAjuste === 'ADD') {
            acc.volMR += el.valorAjusteMR;
            acc.volM3 += el.valorAjusteM3;
            acc.volMROriginal += el.valorAjuste;
          } else {
            acc.volMR -= el.valorAjusteMR;
            acc.volM3 -= el.valorAjusteM3;
            acc.volMROriginal -= el.valorAjuste;
          }

          acc.volMR = +(acc.volMR).toFixed(2);
          acc.volM3 = +(acc.volM3).toFixed(2);
          acc.volMROriginal = +(acc.volMROriginal).toFixed(2);
          return acc;
        }, { volMR: 0, volM3: 0, volMROriginal: 0 });
      }
      isShowingDetails.value = isDetails;
    };

    const requestAllRumas = async () => {
      try {
        const params = cleanSource(filter);
        !params.estado && (params.estado = '60dfe7c9ba0ccfd21a8e04f3');
        isLoading.value = true;
        const request = await makeRequest<{ total: number; items: IRumaApi[] }>(METHODS.GET, '/rumas', true, {}, params);
        if (request.ok) {
          if (request.payload?.items) {
            itemsCount.actual = request.payload.items.length;
            itemsCount.total = request.payload.total;
            itemsCount.initial = 1;
            items.value = request.payload?.items.map((el) => ({
              uid: el._id,
              data: {
                _id: el._id,
                ruma: el.numero,
                estadoRuma: el.estadoRuma.descripcion,
                condicionRuma: el.estadoMadera.descripcion,
                cancha: el.cancha.descripcion,
                codCancha: el.cancha.codigo,
                especie: el.especie.descripcion,
                createdBy: el.createdBy.nombre,
                createdAt: el.createdAt,
                anioPlantacion: el.anioPlantacion + '',
                mesCorta: el.mesCorta,
                volCalculado: el.volCalculado + '',
                volMR: el.volMR + '',
                volM3: el.volM3 + '',
                largo: el.largo + '',
                producto: el.producto?.join(', '),
                img: el.img ? el.img : '',
                geo: el.geo ? el.geo : { lat: 0, lng: 0 }

              }
            }));
          } else items.value = [];
        }
      } catch (error) {
        console.log(error);
      } finally {
        isLoading.value = false;
      }
    };
    const userStoreState = userState;
    onMounted(async () => {
      await requestAllRumas();
      // if (userStoreState.value?.role === 'ADMINISTRADOR') {
      //   const i = headers.value.findIndex((el) => el.key === 'volCalculado');
      //   headers.value[i].hidden = false;
      // }
    });

    // edit ruma
    const editRumaEspecieRef = ref<ComponentPublicInstance<typeof UITextDropdown> | null>(null);
    const editRumaEstadoMaderaRef = ref<ComponentPublicInstance<typeof UITextDropdown> | null>(null);
    const editRumaEstadoRumaRef = ref<ComponentPublicInstance<typeof UITextDropdown> | null>(null);

    const especieDefault = ref('');
    const estadoMaderaDefault = ref('');
    const estadoRumaDefault = ref('60dfe7c9ba0ccfd21a8e04f3');

    const rumaStatusUpdate = async (i: number) => {
      const r = items.value[i];
      if (r) {
        const request = await makeRequest<any>(METHODS.PATCH, `/ruma-status/${r.uid}`, true);
        if (request.ok) {
          showToast('SUCCESS', 'Estado de ruma actualizado');
        } else {
          showToast('ERROR', request.errors + '');
        }
      }
    };

    const rumaEditModal = async (i: number) => {
      const r = items.value[i];
      ruma.id = r.uid;
      ruma.numero = r.data.ruma;
      const estadoM = estadoMadera.find((el) => el.label === r.data.condicionRuma);
      ruma.estadoMadera = estadoM ? estadoM.id : '';
      estadoMaderaDefault.value = ruma.estadoMadera;
      const epc = especies.find((el) => el.label === r.data.especie);
      ruma.especie = epc ? epc.id : '';
      especieDefault.value = ruma.especie;
      const est = estadoRuma.find((el) => el.label === r.data.estadoRuma);
      ruma.estadoRuma = est ? est.id : '';
      estadoRumaDefault.value = ruma.estadoRuma;
      modalState.value = true;
      //
      await requestRumnaGuides(i);
    };
    const ruma = reactive({
      id: '',
      numero: 0,
      especie: '',
      estadoMadera: '',
      estadoRuma: '60dfe7c9ba0ccfd21a8e04f3'
    });
    const especies = especiesState.value.map((el) => ({
      id: el._id,
      label: el.descripcion
    }));
    const estadoMadera = estadoMaderasState.value.map((el) => ({
      id: el._id,
      label: el.descripcion
    }));
    const estadoRuma = estadoRumasState.value.map((el) => ({
      id: el._id,
      label: el.descripcion
    }));
    const isUpdating = ref(false);
    const updateRuma = async () => {
      if (isUpdating.value) return;
      try {
        console.log({ ruma });
        const request = await makeRequest(METHODS.PATCH, `/ruma/${ruma.id}`, true, cleanSource(ruma));
        if (request.ok) {
          showToast('SUCCESS', 'Ruma Editada');
        } else {
          showToast('ERROR', request.errors + '');
        }
      } catch (error) {
        console.log(error);
        showToast('ERROR', 'Error actualizando ruma');
      } finally {
        isUpdating.value = false;
        modalState.value = false;
      }
    };

    //
    const requestRumnaGuides = async (i: number) => {
      const r = items.value[i];
      const request = await makeRequest<any>(METHODS.GET, `ruma-guias/${r.uid}`, true);
      if (request.ok) {
        console.log(request.payload);
      }
    };

    const onFilterRequest = async (f: { [k: string]: any }) => {
      if (isLoading.value) return;
      filter.especie = f.especie;
      filter.cancha = f.cancha;
      filter.estado = f.estado;
      filter.ruma = '';
      singleRumaSearchRef.value?.clearValue();
      await requestAllRumas();
    };

    const unfilter = async () => {
      console.log('unfilter');
      filter.especie = null;
      filter.cancha = null;
      filter.estado = null;
      filter.page = 1;
      filter.ruma = '';
      singleRumaSearchRef.value?.clearValue();
      await requestAllRumas();
    };

    const onPagedRequest = async (q: number) => {
      if (isLoading.value) return;
      filter.qty = q;
      filter.page = 1;
      filter.ruma = '';
      singleRumaSearchRef.value?.clearValue();
      await requestAllRumas();
    };

    const onPageRequest = async (page: number) => {
      if (isLoading.value) return;
      filter.page = page;
      filter.ruma = '';
      singleRumaSearchRef.value?.clearValue();
      await requestAllRumas();
    };

    const searchSingleRuma = async () => {
      filter.especie = null;
      filter.cancha = null;
      filter.estado = null;
      filter.page = 1;
      await requestAllRumas();
    };

    const requestReport = async () => {
      const params = cleanSource(filter);
      params.qty = 0;
      params.page = 1;
      const url = '/report/ruma';
      return makeRequest<{
        total: number;
        items: any[];
      }>(METHODS.POST, url, true, { descripcion: 'Rumas' }, params);
    };

    const socket = SocketIO.getInstance();
    const addRumaCb = (el: any) => {
      if (filter.page > 1) return;
      items.value.unshift({
        uid: el._id,
        data: {
          _id: el._id,
          ruma: el.numero,
          estadoRuma: el.estadoRuma.descripcion,
          condicionRuma: el.estadoMadera.descripcion,
          cancha: el.cancha.descripcion,
          codCancha: el.cancha.codigo,
          especie: el.especie.descripcion,
          createdBy: el.createdBy.nombre,
          createdAt: el.createdAt,
          anioPlantacion: '',
          mesCorta: '',
          volCalculado: '',
          volMR: '',
          volM3: '',
          largo: '',
          producto: el.producto?.join(', ')
        }
      });
      items.value.sort((a, b) => (a.data.createdAt || 0) - (b.data.createdAt || 0));
      if (items.value.length > filter.qty) items.value.pop();
    };
    const updateRumaCb = (el: any) => {
      const i = items.value.findIndex((it) => it.uid === el._id);
      if (i !== -1) {
        items.value[i].data.ruma = el.numero;
        items.value[i].data.estadoRuma = el.estadoRuma.descripcion;
        items.value[i].data.condicionRuma = el.estadoMadera.descripcion;
        items.value[i].data.cancha = el.cancha.descripcion;
        items.value[i].data.codCancha = el.cancha.codigo;
        items.value[i].data.especie = el.especie.descripcion;
      }
    };
    const updateRumaStatusCb = (data: any) => {
      const ruma = items.value.find((el) => el.uid === data._id);
      ruma && (ruma.data.estadoRuma = data.estadoRuma.descripcion);
    };
    onMounted(async () => {
      const roomEvents = [
        { evt: 'new-ruma', cb: addRumaCb },
        { evt: 'update-ruma', cb: updateRumaCb },
        { evt: 'ruma-status', cb: updateRumaStatusCb }
      ];
      await socket.joinRoom('rumas', roomEvents);
    });
    onBeforeUnmount(async () => {
      await socket.leaveRoom('rumas');
    });


    const { clearRumaImage, setRumaImage, getGeoRumas, mapRumas, resetGeoData:resetRumaGeoData  } = useRumaImage();

    const handleRumaFile = async (evt: { id: string; file: any }) => {
      const data = await setRumaImage(evt.id, evt.file);
      if(data) {
        const { id, ruma } = data;
        const i = items.value.findIndex(el => el.uid === id);
        if (i !== -1) {
          items.value[i].data.img = ruma.img;
          items.value[i].data.geo = ruma.geo;
          await nextTick();
          tableKey.value += 1;
        }
      }
    };

    const updateImage = () => {
      selectedMapData.value && mainTable.value.pickFile(selectedMapData.value.id);
      mapModalState.value = false;
    };

    const mapModalState = ref(false);
    const selectedMapData = ref<{
      id: string;
      geo: { lat: number; lng: number };
      n: string;
      img: string;
      cancha: string;
    } | null>(null);

    const selectedMap = (ruma: any) => {
      selectedMapData.value = {
        id: ruma._id,
        geo: { lat: ruma.geo.lat, lng: ruma.geo.lon },
        n: ruma.ruma,
        cancha: ruma.cancha,
        img: ruma.img
      };
      mapModalState.value = true;
    };
    watch(mapModalState, val => {
      if (val === false) {
        selectedMapData.value = null;
      }
    });

    const mapMarker = ref<string | null>(null);
    const openMarker = (id: null | string) => {
      mapMarker.value = id;
    };

    const showMapDrawer = ref(false);
    const mapDrawerRuma = ref<any>(null);
    const showDetailedMap = async (ruma: any) => {
      const request = await makeRequest<any>(METHODS.GET, `/ruma/${ruma.id}`, true);
      if (request.ok) {
        mapDrawerRuma.value = { data: request.payload };
        showMapDrawer.value = true;
      }
    };

    //

    const showFullMap = ref(false);
    const showFullMapFn = async () => {
      await getGeoRumas(filter.cancha);
      showFullMap.value = true;
    };

    const resetGeoData = async (rumaId: string) => {
      await resetRumaGeoData(rumaId);
      mapModalState.value = false;
      const i = items.value.findIndex(el => el.uid === rumaId);
      if (i !== -1) {
        items.value[i].data.geo = { lat: 0, lng: 0 };
        items.value[i].data.img = '';
        await nextTick();
        tableKey.value += 1;
      }
    };

    return {
      routeName,
      headers,
      actions,
      modalState,
      ruma,
      items,
      itemsCount,
      especies,
      estadoMadera,
      estadoRuma,
      singleRumaSearchRef,
      filter,
      searchSingleRuma,
      onPagedRequest,
      onPageRequest,
      unfilter,
      isLoading,
      onFilterRequest,
      requestReport,
      updateRuma,
      isUpdating,
      editRumaEspecieRef,
      editRumaEstadoMaderaRef,
      editRumaEstadoRumaRef,
      especieDefault,
      estadoMaderaDefault,
      estadoRumaDefault,
      userStoreState,
      modalStateGuides,
      rumaGuidesDetails,
      rumaGuideSelected,
      rumaGuidesHeaders,
      rumaGuideAjustes,
      rumaGroups,
      setRumaGroupsGuides,
      isShowingDetails,
      getZonaById,
      rumaAjustesList,
      ajustesHeaders,
      rumaAjustedFilteres,
      rumaGroupTotals,
      ajusteGroupTotals,
      clearRumaImage,
      handleRumaFile,
      mapModalState,
      selectedMap,
      selectedMapData,
      openMarker,
      mapMarker,
      mainTable,
      updateImage,
      showFullMap,
      showFullMapFn,
      mapRumas,
      showDetailedMap,
      mapDrawerRuma,
      showMapDrawer,
      tableKey,
      resetGeoData
    };
  }
});
